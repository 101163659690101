export const Bio = {
  name: "Sony Nase",
  roles: [
    "Full Stack Developer",
    "Programmer",
    "Freelancer",
    "Gamer",
  ],
  description:
    "Adept in JavaScript, C# & PHP, I craft dynamic front-end experiences and robust back-end systems. My expertise ensures engaging web solutions that exceed expectations, showcasing a dedication to impactful digital innovation.",
  github: "https://github.com/nase01",
  resume: "#contact",
  linkedin: "https://www.linkedin.com/in/sony-nase-36a430112",
  twitter: "#",
  insta: "#",
  facebook: "#"
};

export const skills = [
  {
    title: "Front-End",
    skills: [
      {
        name: "PHP",
        image:
          "https://www.spnase.com/assets/images/web-tech-php.png",
      },
      {
        name: "Laravel",
        image:
          "https://www.spnase.com/assets/images/web-tech-laravel.png",
      },
      {
        name: "NextJs",
        image:
          "https://www.spnase.com/assets/images/web-tech-nextjs.png",
      },
      {
        name: "React",
        image:
          "https://www.spnase.com/assets/images/web-tech-react.png",
      },
      {
        name: "Vue",
        image:
          "https://www.spnase.com/assets/images/web-tech-vue.png",
      },
      {
        name: "HTML",
        image: "https://www.spnase.com/assets/images/web-tech-html.png",
      },
      {
        name: "CSS",
        image:
          "https://www.spnase.com/assets/images/web-tech-css.png",
      },
      {
        name: "Tailwind",
        image:
          "https://www.spnase.com/assets/images/web-tech-tailwind.png",
      },
      {
        name: "JavaScript",
        image:
          "https://www.spnase.com/assets/images/web-tech-javascript.png",
      },
      {
        name: "JQuery",
        image:
          "https://www.spnase.com/assets/images/web-tech-jquery.png",
      },
      {
        name: "C#",
        image:
          "https://www.spnase.com/assets/images/web-tech-csharp.png",
      },
    ],
  },
  {
    title: "Back-End",
    skills: [
      {
        name: "NodeJs",
        image: "https://www.spnase.com/assets/images/web-tech-node.png",
      },
      {
        name: "MySQL",
        image:
          "https://www.spnase.com/assets/images/web-tech-mysql.png",
      },
      {
        name: "Postgresql",
        image: "https://www.spnase.com/assets/images/web-tech-postgres.png",
      },
      {
        name: "MongoDB",
        image:
          "https://www.spnase.com/assets/images/web-tech-mongodb.png",
      },
      {
        name: "Sequelize",
        image:
          "https://www.spnase.com/assets/images/web-tech-sequelize.png",
      },
      {
        name: "Sanity",
        image:
          "https://www.spnase.com/assets/images/web-tech-sanity.png",
      },
      {
        name: "Golang",
        image:
          "https://www.spnase.com/assets/images/web-tech-golang.png",
      }
    ],
  },
  {
    title: "Tools",
    skills: [
      {
        name: "FileZilla",
        image:
          "https://www.spnase.com/assets/images/web-tech-filezilla.png",
      },
      {
        name: "VS Code",
        image:
          "https://www.spnase.com/assets/images/web-tech-vscode.png",
      },
      {
        name: "Putty",
        image:
          "https://www.spnase.com/assets/images/web-tech-putty.png",
      }
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://www.spnase.com/assets/images/web-tech-git.png",
      },
      {
        name: "GitHub",
        image:
          "https://www.spnase.com/assets/images/web-tech-github.png",
      },
      {
        name: "Netlify",
        image:
          "https://www.spnase.com/assets/images/web-tech-netlify.png",
      },
      {
        name: "Vercel",
        image:
          "https://www.spnase.com/assets/images/web-tech-vercel.png",
      },
      {
        name: "Postman",
        image:
          "https://www.spnase.com/assets/images/web-tech-postman.png",
      },
      {
        name: "Figma",
        image:
          "https://www.spnase.com/assets/images/web-tech-figma.png",
      },
      {
        name: "Web CPanel",
        image:
          "https://www.spnase.com/assets/images/web-tech-cpanel.png",
      }
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "https://www.spnase.com/assets/images/ttec-logo.png",
    role: "Senior Application Developer",
    company: "TTEC Philippines",
    website: "https://www.ttec.com",
    date: "Mar 2024 - Present",
    desc: "Software development, including designing, coding, testing, and debugging complex applications. Maintaining existing multi-tenant interprise applications and use AI for automation.",
    skills: [
      "Golang",
      "React",
      "C#(.Net)",
      "MySQL",
      "HML",
      "CSS",
      "JQuery",
      "JavaScript",
      "GCP",
      "LLM",
      "Generative AI",
    ],
    doc: "",
  },
  {
    id: 1,
    img: "https://www.spnase.com/assets/images/accustack-logo.png",
    role: "Front-End Developer",
    company: "Accustack",
    website: "https://accustack.ph",
    date: "Feb 2021 - Present",
    desc: "I hold an ownership role, while also actively contributing to front-end projects. My focus lies in transforming high-fidelity prototypes into fully functional Vue components and pages, bringing designs to life.",
    skills: [
      "Vue",
      "NodeJs",
      "JavaScript",
      "HML",
      "CSS"
    ],
    doc: "",
  },
  {
    id: 2,
    img: "https://www.spnase.com/assets/images/ims-logo.png",
    role: "Sr. Programmer",
    company: "Integrated Management Services Inc.",
    website: "https://ims.com.ph",
    date: "Jan 2012 - Feb 2024",
    desc: "As a pioneer within the company, I serve as the head of the Systems Development and Maintenance unit. In this role, I lead the development of projects for full-stack web applications and ensure the ongoing maintenance of existing systems.",
    skills: [
      "Visual C#",
      "PHP",
      "MySQL",
      "HML",
      "CSS",
      "JQuery",
      "JavaScript",
    ],
    doc: "",
  },
  {
    id: 3,
    img: "https://www.spnase.com/assets/images/idmc-logo.png",
    role: "Jr. Programmer",
    company: "IMS Data Management Center Inc.",
    website: "",
    date: "Jun 2009 - Jan 2012",
    desc: "Responsible for both coding and designing assigned project web pages or system modules.",
    skills: [
      "PHP",
      "MySQL",
      "Visual Studio 6.0"
    ],
    doc: "",
  },
  {
    id: 4,
    img: "https://www.spnase.com/assets/images/secomp-logo.png",
    role: "IT Staff",
    company: "SeComp Solutions Inc.",
    website: "https://www.secompsolutions.com",
    date: "Jan 2009 - Jun 2009",
    desc: "Engaged in various IT-related tasks, including data encoding, printing, and programming.",
    skills: [
      "HTML",
      "Troubleshooting",
      "MS Office",
    ],
    doc: "",
  }
];

export const education = [
  {
    id: 0,
    img: "https://www.spnase.com/assets/images/educ-aics.png",
    school: "Asian Institute of Computer Studies (Bicutan)",
    date: "2009",
    grade: "",
    desc: "",
    degree: "Vocational/2yr in Computer Science",
  }
];

export const projects = [
  {
    id: 1,
    title: "ANEC Admin Panel",
    date: "Jul 2021",
    description:
      "The front-end of the ANEC Global Admin Panel enables efficient tracking of orders and effective management of merchant data.",
    images: [
      "https://www.spnase.com/assets/images/projects/project-anecadmin-01.jpg",
      "https://www.spnase.com/assets/images/projects/project-anecadmin-02.jpg",
      "https://www.spnase.com/assets/images/projects/project-anecadmin-03.jpg",
    ],
    tags: [
      "VueJS",
      "HTML",
      "CSS",
    ],
    category: "front-end",
    github: "",
    webapp: "https://ap.anec.global/",
  },
  {
    id: 2,
    title: "Authninja API",
    date: "Sept 2022",
    description:
      "This is a NodeJS + MongoDB JWT authentication and user management API designed to facilitate the initiation of projects by providing pre-built endpoints so the developers can focus on more important thing right away.",
    images: [
      "https://www.spnase.com/assets/images/projects/project-authninja-api.jpg",
    ],
    tags: [
      "NodeJS",
      "Express",
      "MongoDB",
      "JWT"
    ],
    category: "back-end",
    github: "https://github.com/nase01/authninja-node-express-rest-api",
  },
  {
    id: 3,
    title: "Uzzap",
    date: "Aug 2023",
    description:
      "This social media application empowers users to efficiently manage their profiles and establish communities for seamless thread-based message sharing. It employs clerk user management to ensure robust authentication and relies on MongoDB as a secure data storage solution.",
    images: [
      "https://www.spnase.com/assets/images/projects/project-uzzap-01.jpg",
      "https://www.spnase.com/assets/images/projects/project-uzzap-02.jpg",
      "https://www.spnase.com/assets/images/projects/project-uzzap-03.jpg",
      "https://www.spnase.com/assets/images/projects/project-uzzap-04.jpg",
    ],
    tags: [
      "NextJs",
      "MongoDB",
      "Tailwind",
    ],
    category: "full-stack",
    github: "https://github.com/nase01/uzzap-socmed",
    webapp: "https://uzzap.vercel.app/",
  },
  {
    id: 4,
    title: "SavorMNL",
    date: "Oct 2023",
    description:
      "A full-stack recipe app developed using Next.js server-side rendering for fast content loading speed, utilizing Sanity's CRUD functionality to efficiently manage website data. Users can easily log in using their Google accounts, enabling them to save or delete favorite recipes. Moreover, Sanity Studio is seamlessly integrated, granting instant access to data, simplifying content creation and editing.",
    images: [
      "https://www.spnase.com/assets/images/projects/project-savormnl-01.jpg",
      "https://www.spnase.com/assets/images/projects/project-savormnl-02.jpg",
      "https://www.spnase.com/assets/images/projects/project-savormnl-03.jpg",
      "https://www.spnase.com/assets/images/projects/project-savormnl-04.jpg",
    ],
    tags: [
      "NextJs",
      "Sanity",
      "Tailwind",
    ],
    category: "full-stack",
    github: "https://github.com/nase01/nextjs-savormnl-food-recipe-app",
    webapp: "https://savormnl.vercel.app/",
  },
  {
    id: 5,
    title: "Purple Box",
    date: "Oct 2023",
    description:
      "This robust full-stack application harnesses diverse AI models sourced from OpenAI and Replicate, enabling users to engage in multifaceted interactions akin to ChatGPT, create dynamic content such as images, videos, and music, all while seamlessly offering a Stripe payment gateway for convenient user subscription management. ",
    images: [
      "https://www.spnase.com/assets/images/projects/project-purplebox-01.jpg",
      "https://www.spnase.com/assets/images/projects/project-purplebox-02.jpg",
      "https://www.spnase.com/assets/images/projects/project-purplebox-03.jpg",
      "https://www.spnase.com/assets/images/projects/project-purplebox-04.jpg",
      "https://www.spnase.com/assets/images/projects/project-purplebox-05.jpg",
      "https://www.spnase.com/assets/images/projects/project-purplebox-06.jpg",
    ],
    tags: [
      "NextJs",
      "MongoDB",
      "Tailwind",
      "Stripe",
    ],
    category: "full-stack",
    github: "https://github.com/nase01/nextjs-purplebox-sassai.app",
    webapp: "https://purplebox.vercel.app/",
  },
  {
    id: 6,
    title: "ERaven",
    date: "Jan 2024",
    description:
      "The comprehensive full-stack e-commerce application boasts essential cart functionalities, seamless online payment processing, a user-friendly infinite scroll for effortless product exploration, advanced filtering and searching capabilities for enhanced user navigation, and a robust Content Management System (CMS) featuring Sanity Studio for streamlined content administration.",
    images: [
      "https://www.spnase.com/assets/images/projects/project-eraven-01.jpg",
      "https://www.spnase.com/assets/images/projects/project-eraven-02.jpg",
      "https://www.spnase.com/assets/images/projects/project-eraven-03.jpg",
      "https://www.spnase.com/assets/images/projects/project-eraven-04.jpg",
      "https://www.spnase.com/assets/images/projects/project-eraven-05.jpg",
      "https://www.spnase.com/assets/images/projects/project-eraven-06.jpg"
    ],
    tags: [
      "NextJs",
      "Tailwind",
      "Stripe",
      "Sanity",
    ],
    category: "full-stack",
    github: "https://github.com/nase01/nextjs-basic-ecommerce",
    webapp: "https://eraven.vercel.app/",
  }
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2019, text: "Landed I job" },
  { year: 2018, text: "Worked as a freelance developer" },
];
